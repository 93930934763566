import React from "react"
import { graphql } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Layout from "../components/layout"
import Container from "../components/container"
import Seo from "../components/seo"
import { convertRaw, renderRawText } from "../helpers/rich-text"

const Impressum = ({
  path,
  data: {
    page: { title, text },
  },
}) => {
  return (
    <Layout path={path}>
      <Seo title="Impressum" />
      <Container verticalSpaceSm>
        <h1 className="text-2xl text-gray-600 tracking-widest mb-12">
          {title}
        </h1>
        <div className="md:columns-3">
          <div className="text-xs text-gray-500 tracking-widest">
            {documentToReactComponents(convertRaw(text), renderRawText)}
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export default Impressum

export const pageQuery = graphql`
  query ImpressumPage {
    site {
      siteMetadata {
        siteUrl
      }
    }
    page: contentfulImpressum(
      id: { eq: "b27903c2-4fda-51f5-953a-c051d4e8620a" }
    ) {
      title
      text {
        raw
      }
    }
  }
`
